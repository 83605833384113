import React from 'react';
import HomePage from "./pages/HomePage";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LinksPage from "./pages/LinksPage";
import Header from "./components/Header";

const App = () => {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/links" element={<LinksPage/>}/>
                <Route path={"*"} element={<Navigate to={"/"}/>}/>
            </Routes>
        </BrowserRouter>
    )
};

export default App;
