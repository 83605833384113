import React, {lazy, Suspense} from 'react';

const Contacts = lazy(() => import('../components/Contacts'));
const AboutUs = lazy(() => import('../components/AboutUs'));
const Footer = lazy(() => import('../components/Footer'));
const Pricing = lazy(() => import('../components/Pricing'));
const Address = lazy(() => import('../components/Address'));
const Wrapper = lazy(() => import('../components/DayItem'));
const StepDetails = lazy(() => import('../components/StepDetails'));
const FeedVideo = lazy(() => import('../components/LazyLoadingVideo'));
const HeroBlock = lazy(() => import('../components/Hero'));

export interface Times {
    icon: string,
    str_time: string,
    time: string
}

export interface SourceVideo {
    url: string,
    fallback_url: string
}

export interface FeedItem {
    day: string,
    name: string,
    description: string,
    times: Times[],
    source_video: SourceVideo
}


const HomePage = () => {
    const [steps, setSteps] = React.useState<FeedItem[]>([])
    const [playingVideo, setPlayingVideo] = React.useState('')

    React.useEffect(() => {
        fetch('/steps.json')
            .then((response) => response.json())
            .then((response: FeedItem[]) => setSteps(response))
            .catch((error) => console.log(error))
            .finally(() => console.log("Page loaded"))
    }, [])

    return (

        <Suspense fallback={<div>Loading...</div>}>
            <div className={"bg-blur bg-light"}>
                <HeroBlock/>
                <AboutUs/>
                <div className={"bg-blur bg-light"}>
                    <div className="container pt-5 pt-5" id={"programs"}>
                        <h2 className="pb-2 border-bottom display-6">Программы</h2>
                    </div>
                    {steps.map((value) => (
                        <Wrapper key={value.day}>
                            <div className="container mt-5 mb-5">
                                <div className="row justify-content-center align-items-center">
                                    {/*{value?.source_video && (*/}
                                    {/*    <div className={"col-lg-4 mb-4 mb-lg-0"}>*/}
                                    {/*        <FeedVideo*/}
                                    {/*            source={value.source_video}*/}
                                    {/*            isPlayingId={playingVideo}*/}
                                    {/*            setCurrentVideo={setPlayingVideo}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                    <div className="col-lg-6 d-flex flex-column justify-content-around">
                                        <StepDetails item={value}/>
                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    ))}
                </div>
                <Pricing/>
                <Contacts/>
                <Address/>
                <Footer/>
            </div>
        </Suspense>
    );
};

export default HomePage;
