import React from 'react';

const Index = ({children, useBackground = {}}: any) => {


    const styles = {
        ...useBackground
        ,
        ...{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // minHeight: "100vh",
        }
    }

    return (
        <article style={styles}>
            {children}
        </article>
    );
};

export default Index;