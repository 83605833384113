import React from 'react';
import Wrapper from "../components/DayItem";
import { Button, Card, Image } from "react-bootstrap";
import { TELEGRAM, WHATSAPP, TIKTOK } from "../api/contacts";
import { useNavigate } from "react-router-dom";

const LinksPage = () => {
    const bus = useNavigate()
    const handleOpenLink = (link: string) => {
        window.open(link, "_blank");
    };

    const handleGoHome = () => bus("/")

    return (
        <div className={"bg-blur bg-light"}>
            <Wrapper>
                <div className="container-sm d-flex justify-content-center">
                    <Card style={{maxWidth: "400px"}}
                          className={"justify-content-center align-items-center p-2 shadow-sm gap-2"}>
                        <div className={"d-flex flex-column justify-content-center align-items-center"}>
                            <Image className="d-block mx-auto mb-4" src="logo512.png" alt="" width="150px"/>
                            <p className="col-lg-10 fs-3 fw-bold lh-1 text-body-emphasis text-center">
                                DMB UAE</p>
                            <p className="col-lg-10 fs-5 mb-0 text-center ">
                                Добро пожаловать на программу "Обучение, Путешествие и Трудоустройство в Дубае"!
                            </p>
                        </div>
                        <div className="d-flex flex-column border-0 p-2 w-100 gap-2">
                            <Button
                                onClick={() => handleOpenLink(TELEGRAM)}
                                variant={"primary"} size={'lg'}>
                                <i className={"bi-telegram"}>Telegram</i>
                            </Button>
                            <Button
                                onClick={() => handleOpenLink(WHATSAPP)}
                                variant={"primary"} size={'lg'}>
                                <i className={"bi-whatsapp"}>WhatsApp</i>
                            </Button>
                            <Button
                                onClick={() => handleOpenLink(TIKTOK)}
                                variant={"primary"} size={'lg'}>
                                <i className={"bi-tiktok"}>TikTok</i>
                            </Button>
                            <Button
                                onClick={handleGoHome}
                                variant={"primary"} size={'lg'}>Официальный сайт</Button>
                        </div>
                    </Card>
                </div>
            </Wrapper>
        </div>
    );
};

export default LinksPage;