import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { scrollToBlog } from "../../utils/scroll";
import DmbLogo from "../DmbLogo";
import "./style.css"

function MyNavbar() {
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(true);
    const toggleNavbar = () => {
        setNavbarExpanded(!navbarExpanded);
    };

    const closeNavbar = () => {
        setNavbarExpanded(false);
    };

    React.useEffect(() => {
        let prevScrollPos = window.pageYOffset;

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
            setNavbarVisible(visible);
            if (navbarExpanded) closeNavbar()
            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar expanded={navbarExpanded} expand="md" className={`navbar-expand-md bg-body-tertiary fixed-top custom-navbar ${navbarVisible ? 'visible' : 'invisible'}`}>
            <Container>
                <Navbar.Brand href="#">
                    <DmbLogo/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navmenu" onClick={toggleNavbar} />
                <Navbar.Collapse id="navmenu">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => { scrollToBlog("about_us"); closeNavbar(); }} className="nav-link m-3 fw-semibold">О нас</Nav.Link>
                        <Nav.Link onClick={() => scrollToBlog("programs")} className="nav-link m-3 fw-semibold">Программа</Nav.Link>
                        <Nav.Link onClick={() => { scrollToBlog("price"); closeNavbar(); }} className="nav-link m-3 fw-semibold">Цены</Nav.Link>
                        <Nav.Link onClick={() => { scrollToBlog("contacts"); closeNavbar(); }} className="nav-link m-3 fw-semibold">Контакты</Nav.Link>
                        <Nav.Link onClick={() => { scrollToBlog("map"); closeNavbar(); }} className="nav-link m-3 fw-semibold">Наш адрес</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            {navbarExpanded && (
                <div onClick={closeNavbar} className="overlay"></div>
            )}
        </Navbar>
    );
}

export default MyNavbar;
